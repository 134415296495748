import React, { Dispatch, FC, memo, SetStateAction } from "react";
import { HiShoppingCart } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AccoladezLogo from "../../packages/design/AccoladezLogo";
import { Tooltip } from "../../packages/design/Tooltip";
import { routes } from "../../packages/utils/routes";
import { AppDispatch } from "../../store";
import { setIsRemainingRewardModal } from "../../store/rewards/rewardSlice";

interface IRewardHeader {
  points: number;
  isReedemActive?: boolean;
  reedemNow: () => any;
  cart: any[];
  isHomePage?: boolean;
}

const RewardHeader: FC<IRewardHeader> = memo(({
  points,
  isReedemActive,
  reedemNow,
  cart,
  isHomePage,
}) => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  return (
    <div className="block lg:hidden space-y-1  py-2   h-10 md:h-14 fixed fixedhandled top-10 sm:top-16 left-0 w-screen bg-white z-20">
      <section className="flex justify-between space-x-1  ">
        <div className="flex items-center justify-between w-full  md:w-[calc(100%-362px)] mr-2  px-3 pt-1 md:py-2 xl:py-0 xl:px-6 rounded">
          <AccoladezLogo className="max-w-[9rem]" />
          <div className="flex space-x-1 items-center ">
            <h2 className="hidden xl:block text-base text-black">Balance:</h2>
            <div className="flex items-center space-x-1">
              <img src="/starcoin.png" alt="starcoin" className="h-6 w-6" />
              <span className="text-lg font-medium">{points}</span>
            </div>
          </div>
        </div>

        <div className=" hidden md:flex  space-x-3   ">
          <Tooltip tooltipText="Cart" point="right">
            <div
              onClick={() => {
                navigate(routes.cart)
              }}
              className="flex items-center space-x-2  rounded px-3 cursor-pointer py-1 "
            >
              <div className="w-max relative">
                <HiShoppingCart className="text-3xl " />
                {isHomePage && cart.length > 0 && (
                  <div className="absolute -top-[calc(1rem/2)] -right-[calc(1rem/2)] text-white b rounded-full bg-red-400 flex items-center justify-center w-4 h-4 text-[10px] font-medium ">
                    {cart?.length}
                  </div>
                )}
              </div>
            </div>
          </Tooltip>
          <button
            onClick={() => {
              if (points === 0) {
                dispatch(setIsRemainingRewardModal(false))
                reedemNow();
              } else {
                dispatch(setIsRemainingRewardModal(true))
                reedemNow();
              }
            }}
            disabled={!isReedemActive}
            className={`${
              isReedemActive ? "bg-[rgba(242,78,0)]" : "bg-[rgba(242,78,0,0.4)]"
            } text-white w-max text-center  rounded px-4 text-sm  hidden md:block `}
          >
            Reedem
          </button>
        </div>
      </section>

      {/* <div className="h-[0.1rem] bg-[#D9D9D9] "></div> */}
    </div>
  );
});

export default RewardHeader;
