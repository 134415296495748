import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { CartFooter, CartHeader, CartOrders } from "../components/cart";
import MobileCartOrder from "../components/cart/MobileCartOrder";
import { TokenUser, useAppContext } from "../context";
import { Tracker } from "../lib/sentry";
import { createOrder, updateRewardInCart } from "../packages/api/cart";
import { IRewards } from "../packages/interface/rewards";
import { AppDispatch, RootState } from "../store";
import { setCart, updateCart } from "../store/cart/cartSlice";
import { setIsCongratsModal } from "../store/rewards/rewardSlice";
import { setOrderToken } from "../store/user/userSlice";

// cart: IRewards[];
interface ICart {
  balance: number;
  setBalance: Dispatch<SetStateAction<number>>;
}

const Cart: FC<ICart> = ({
  balance,
  setBalance,
}) => {
  const tracker = new Tracker();

  const {
    isShowReedemActiveModal,
    setIsShowReedemActiveModal,
    isMobileReward,
    setNotification,
    setNotificationData,
    rewardsLoading,
    setRewardsLoading, token
  } = useAppContext();

  const {cart} = useSelector((state:RootState) => state.cart)
  const dispatch  = useDispatch<AppDispatch>()


  useEffect(() => {
    if (isMobileReward === false) {
      setIsShowReedemActiveModal(false);
    }
  }, [isShowReedemActiveModal]);

  const handleCheckout = () => {
    createOrder(token).then((res: any) => {
      tracker.orderGenerated();
      if (res.status === 201) {
        dispatch(setIsCongratsModal(true))
        dispatch(setOrderToken(res?.data?.data?.message?.order_token))
      }
    });
  };
  const removeRewardFromCart = useCallback(
    (title: string, image: string, points: number, sku: string) => {
      setRewardsLoading({ status: true, sku });
      updateRewardInCart(token, sku, "remove")
        .then((res: any) => {
          if (res.status === 200) {
            tracker.rewardAction(sku, "remove");
            // const newCart = cart.filter((item: any) => item.sku !== sku);
            dispatch(updateCart(sku))
            // dispatch(setCart(newCart))
            setBalance((prevBal: number) => {
              return prevBal + points;
            });

            let notyData = {
              id: sku,
              msg: "Reward Removed",
              isShow: true,
              data: { title, image, points },
            };
            setNotificationData((prev: any) => {
              if (prev.length > 0) {
                return [notyData, ...prev];
              }
              return [notyData];
            });
            setRewardsLoading({ status: false, sku });
          }
        })
        .catch((e) => {
          console.log(e);
          setRewardsLoading({ status: false, sku });
        });
    },
    [cart]
  );

  return (
    <div className="overflow-hidden px-2">
      <CartHeader />
      <div className="mt-28">
        <CartOrders 
          balance={balance}
          setBalance={setBalance}
          handleCheckout={handleCheckout}
          removeRewardFromCart={(
            title: string,
            image: string,
            points: number,
            sku: string
          ) => removeRewardFromCart(title, image, points, sku)}
        />
        <div className=" ">
          <MobileCartOrder
            removeRewardFromCart={(
              title: string,
              image: string,
              points: number,
              sku: string
            ) => removeRewardFromCart(title, image, points, sku)}
          />
          <CartFooter
            balance={balance}
            handleCheckout={handleCheckout}
          />
        </div>
      </div>
    </div>
  );
};

export default Cart;
