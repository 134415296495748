import React, { Dispatch, FC, SetStateAction } from "react";
import { useAppContext } from "../context";
import { Modal } from "../packages/design";
import { BsEmojiSmile } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { routes } from "../packages/utils/routes";
import { useDispatch } from "react-redux";
import { setIsRemainingRewardModal } from "../store/rewards/rewardSlice";

interface IShowRewardActiveModal {
  reedemNow: () => void;
  balance: number;
}

const ShowRewardActiveModal:FC<IShowRewardActiveModal> = ({reedemNow,balance}) => {
  const { isShowReedemActiveModal, setIsShowReedemActiveModal } =
    useAppContext();
    const navigate = useNavigate()
    const dispatch = useDispatch()
  // onClose={()=>setIsShowReedemActiveModal(false)}
  return (
    <Modal isOpen={isShowReedemActiveModal}>
      <div className=" relative  rounded-lg bg-white flex flex-col items-center w-[90%] md:w-max lg:w-max mx-auto py-8 px-10 space-y-2">
        <BsEmojiSmile className="text-[#F24E00] text-4xl" />
        {balance === 0 ? (
          <>
            <h2 className="text-black font-medium text-sm lg:w-max text-center">
              You have consumed all the points for this subscription.
            </h2>
            <button
              onClick={() => {
                navigate(routes.cart)
              }}
              className="bg-[#6ABD00] text-white px-4 py-2 rounded text-sm "
            >
              Reedem Now
            </button>
          </>
        ) : (
          <>
            <h2 className="text-black font-medium text-sm lg:w-max text-center flex items-center whitespace-nowrap">
              You are left with
              <img src="threecoins.svg" alt="threecoins" className="h-5 w-5 inline ml-1"/>
               {balance} points to redeem.
            </h2>
            <p className="text-xs text-black opacity-40 text-center ">
              We have some suggestion for you
            </p>
            <button
              onClick={() => {
                reedemNow();
                setIsShowReedemActiveModal(false);
                dispatch(setIsRemainingRewardModal(true)) 
              }}
              className="bg-[#6ABD00] text-white px-4 py-2 rounded text-sm "
            >
              Show Suggestion
            </button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ShowRewardActiveModal;
