import React, { Dispatch, memo, SetStateAction, useMemo, useState } from 'react'
import SingleCategoryCard from './SingleCategoryCard'
import { setImagesToBrands, fourItems, setImagesToCategories } from '../../packages/utils/categoryPageData'
import { IFilter } from '../../packages/interface/rewards'
import { useAppContext } from '../../context'
import { getImageUrlBySlug } from '../../packages/api/home'
import slugify from 'slugify'

const CategoryCard = memo(() => {
  const { originalFilters } = useAppContext()

  // randome data brand
  const getRandomBrands = () => {
    const randomDataForBrand: Array<Record<string, number | string>> = []
    if (originalFilters.brands) {
      for (let i = 1; i <= 8; i++) {
        let randomNo = Math.floor(Math.random() * originalFilters.brands.length)
        let randomBrand = originalFilters.brands[randomNo]
        let randomBrandName: string = randomBrand.name
        const slug: string = slugify(randomBrandName, {
          lower: true,
          strict: true,
          trim: true
        })
        const image = `https://accomate.s3.ap-south-1.amazonaws.com/public-assets/${slug}.png`
        randomDataForBrand.push({ ...randomBrand, slug, image })
      }
    }

    window.sessionStorage.setItem('randomdata-for-brand', JSON.stringify(randomDataForBrand))
    return randomDataForBrand
  }

  // random data category
  const getRandomCategories = () => {
    const randomDataForCategory: Array<Record<string, number | string>> = []
    if (originalFilters.category) {
      for (let i = 1; i <= 8; i++) {
        let randomNo = Math.floor(Math.random() * (originalFilters.category.length))
        let randomCategory = originalFilters.category[randomNo]
        let randomCategoryName: string = originalFilters.category[randomNo].name
        const slug: string = slugify(randomCategoryName, {
          lower: true,
          strict: true,
          trim: true
        })
        const image = `https://accomate.s3.ap-south-1.amazonaws.com/public-assets/${slug}.png`
        randomDataForCategory.push({ ...randomCategory, slug, image })
      }
    }

    window.sessionStorage.setItem('randomdata-for-category', JSON.stringify(randomDataForCategory))

    return randomDataForCategory
  }

  if (!window.sessionStorage.getItem('randomdata-for-category')) {
    const randomDataForCategory = getRandomCategories()
  }
  if (!window.sessionStorage.getItem('randomdata-for-brand')) {
    const randomDataForBrand = getRandomBrands()
  }

  const categoryDataFromLocalStorage = eval(window.sessionStorage.getItem('randomdata-for-category') ?? "")
  const brandDataFromLocalStorage = eval(window.sessionStorage.getItem('randomdata-for-brand') ?? "")


  const singleCategoryListData = [
    { data: categoryDataFromLocalStorage?.slice(0, 4), title: "Upgrade your home | Ant Mascot Brand & More", id: "firstcard" },
    { data: brandDataFromLocalStorage?.slice(0, 4), isBrandcard: true, title: "Upgrade your home | Ant Mascot Brand & More", id: "secondcard" },
    { data: categoryDataFromLocalStorage?.slice(4, 8), title: "Upgrade your home | Ant Mascot Brand & More", id: "thirdcard" },
    { data: brandDataFromLocalStorage?.slice(4, 8), isBrandcard: true, title: "Upgrade your home | Ant Mascot Brand & More", id: "fourthcard" },
  ]


  return (
    <section className='px-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4  xl:gap-8 mt-4  lg:mt-8'>
      {singleCategoryListData &&
        singleCategoryListData?.map((item: any, index: number) => {
          return <SingleCategoryCard key={index} {...item} />
        })
      }
    </section>
  )
})

export default CategoryCard