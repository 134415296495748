import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useAppContext } from "../../context";
import { Tracker } from "../../lib/sentry";
import { updateRewardInCart } from "../../packages/api/cart";
import { Spinner } from "../../packages/design";
import { sortHighLowData } from "../../packages/utils/filter";
import { AppDispatch, RootState } from "../../store";
import { setCart, updateCart } from "../../store/cart/cartSlice";
import RewardCard from "./RewardCard";
import RewardHeader from "./RewardHeader";
import UpgradeRewardCard from "./UpgradeRewardCard";
interface IRewardPage {
  setSort: any
  currentShortType: string
  setCurrentShortType: Dispatch<SetStateAction<string>>
  setIsFiltersOn: Dispatch<SetStateAction<boolean>>
  setFilterType: Dispatch<SetStateAction<"filter" | "sort" | null>>
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  rewards: any[];
  balance: number;
  loading: boolean;
  token: string;
  setPage: Dispatch<SetStateAction<number>>;
  setBalance: Dispatch<SetStateAction<number>>;
  reedemNow: () => void;
  filters: any;
  minValue: number;
  maxValue: number;
  sort: any;
  link: any;
  filterValueChangeLoading: boolean;
  isInitialCartLoading: boolean;
}

const Rewards: FC<IRewardPage> = ({
  setSort,
  currentShortType,
  setCurrentShortType,
  setIsFiltersOn,
  setFilterType,
  handleChange,
  rewards,
  balance,
  loading,
  token,
  setPage,
  setBalance,
  reedemNow,
  filters,
  minValue,
  maxValue,
  sort,
  link,
  filterValueChangeLoading, isInitialCartLoading
}) => {
  const {
    setIsModal,
    setModalData,
    setNotificationData,
    setRewardsLoading,
    rewardsLoading,
    containerHeight,
    isMobileReward,
  } = useAppContext();
  const { cart } = useSelector((state: RootState) => state.cart);
  const { isReedemActive } = useSelector((state: RootState) => state.reward);
  const dispatch = useDispatch<AppDispatch>();

  const observer: any = useRef();
  const [addCartArr, setAddCart] = useState<string[]>([]);

  const tracker = new Tracker();

  useEffect(() => {
    let newAddCartArr: any[] = [];
    cart.map((item: any) => {
      newAddCartArr.push(item.sku);
    });
    setAddCart([...newAddCartArr]);
  }, [cart]);

  // console.log(addCartArr,"addCartArr")

  const lastCardElementRef = useCallback(
    (node: any) => {
      if (loading || isInitialCartLoading) return;
      // if (filterValueChangeLoading) return;
      if (observer?.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && link !== null) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  const addRewardsToCart = (
    title: string,
    image: string,
    points: number,
    sku: string
  ) => {
    setRewardsLoading({ status: true, sku });
    updateRewardInCart(token, sku, "add")
      .then((res: any) => {
        if (res.status === 200) {
          tracker.rewardAction(sku, "add");

          const reward = rewards.find((item: any) => item.sku === sku);

          dispatch(setCart([reward]));
          // setCart((prev: any[]) => {
          //   return [...prev, reward];
          // });

          setBalance((prevBal: number) => {
            return prevBal - points;
          });
          let notyData = {
            id: sku,
            msg: "Reward Added",
            isShow: true,
            data: { title, image, points },
          };
          setNotificationData((prev: any) => {
            if (prev.length > 0) {
              return [notyData, ...prev];
            }
            return [notyData];
          });
          setAddCart((prev: any) => {
            return [...prev, sku];
          });

          setRewardsLoading({ status: false, sku });
        }
      })
      .catch((e) => {
        if (e.response?.status === 403) {
          setNotificationData({
            msg: "Reward Already Added",
            isShow: true,
            data: { title, image, points },
          });
          setRewardsLoading({ status: false, sku });
        }
      });
  };

  const removeRewardFromCart = useCallback(
    (title: string, image: string, points: number, sku: string) => {
      setRewardsLoading({ status: true, sku });
      updateRewardInCart(token, sku, "remove")
        .then((res: any) => {
          if (res.status === 200) {
            tracker.rewardAction(sku, "remove");
            // const newCart = cart.filter((item: any) => item.sku !== sku);
            dispatch(updateCart(sku));
            // setCart(newCart);
            setBalance((prevBal: number) => {
              return prevBal + points;
            });

            let notyData = {
              id: sku,
              msg: "Reward Removed",
              isShow: true,
              data: { title, image, points },
            };
            setNotificationData((prev: any) => {
              if (prev.length > 0) {
                return [notyData, ...prev];
              }
              return [notyData];
            });
            setRewardsLoading({ status: false, sku });
          }
        })
        .catch((e) => {
          console.log(e);
          setRewardsLoading({ status: false, sku });
        });
    },
    [cart]
  );
  return (
    <section className="z-0 h-full w-full overflow-y-auto scroll lg:scrollbar-thin lg:scrollbar-thumb-gray-400">
      <div className="space-y-1">
        <div className="flex items-center m-4 space-x-2 text-sm">
          <label className="text-black/50 font-medium">
            Sort by:
          </label>
          <div
            onClick={() => {
              setCurrentShortType('points')
              setSort('points')
            }}
            className={`text-xs py-0.5 px-2 cursor-pointer rounded-full border hover:text-secondary hover:border-secondary 
            ${currentShortType === "points"
                ? "text-secondary border-secondary bg-secondary/10"
                : "text-black border-black"
              }
            `}
          >
            Price - Low to High
          </div>
          <span className="text-black/50 font-medium">|</span>
          <div
            onClick={() => {
              setCurrentShortType('-points')
              setSort('-points')
            }}
            className={`text-xs py-0.5 px-2 cursor-pointer rounded-full border hover:text-secondary hover:border-secondary  ${currentShortType === "-points"
              ? "text-secondary border-secondary bg-secondary/10"
              : "text-black border-black"
              }`}
          >
            Price - High to Low
          </div>
        </div>
      </div>
      <RewardHeader
        cart={cart}
        points={balance}
        isReedemActive={isReedemActive}
        reedemNow={reedemNow}
        isHomePage
      />
      {filterValueChangeLoading ? (
        <section className="h-screen flex items-center justify-center">
          <div className="h-16 w-16">
            <Spinner className="fill-[#F24E00]" />
          </div>
        </section>
      ) : (
        <div>
          {rewards.length > 0 || loading ? (
            <>
              <section
                // style={{
                //   height: isMobileReward
                //     ? "calc(100vh-6.5rem)"
                //     : containerHeight + "px",
                // }}
                className={`mt-20 md:mt-28 lg:mt-0 w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-6 4xl:grid-cols-8 xl:gap-5  gap-2
               h-full  2xl:pr-8  xl:pr-5 pb-16 md:pb-0  `}
              >
                {rewards?.map(
                  (
                    item: {
                      sku: string;
                      id: number;
                      brand: string;
                      title: string;
                      image: string;
                      description: string;
                      points: number;
                    },
                    index: number
                  ) => {
                    if (item.points > balance) {
                      if (rewards.length === index + 1) {
                        return (
                          <div key={index} ref={lastCardElementRef}>
                            <UpgradeRewardCard
                              {...item}
                              setModalData={(e: any) => setModalData(e)}
                              openModal={setIsModal}
                              addToCart={() =>
                                addRewardsToCart(
                                  item.title,
                                  item.image,
                                  item.points,
                                  item.sku
                                )
                              }
                              isRewardLoading={
                                rewardsLoading.status &&
                                rewardsLoading.sku === item.sku
                              }
                            />
                          </div>
                        );
                      }
                      if (
                        item.points > balance &&
                        addCartArr.includes(item.sku)
                      ) {
                        return (
                          <RewardCard
                            key={index}
                            {...item}
                            setModalData={(e: any) => setModalData(e)}
                            openModal={setIsModal}
                            addToCart={() =>
                              addRewardsToCart(
                                item.title,
                                item.image,
                                item.points,
                                item.sku
                              )
                            }
                            isChecked={addCartArr.includes(item.sku)}
                            isRewardLoading={
                              rewardsLoading.status &&
                              rewardsLoading.sku === item.sku
                            }
                            removeFromCart={() =>
                              removeRewardFromCart(
                                item.title,
                                item.image,
                                item.points,
                                item.sku
                              )
                            }
                          />
                        );
                      }
                      return (
                        <div key={index}>
                          <UpgradeRewardCard
                            {...item}
                            setModalData={(e: any) => setModalData(e)}
                            openModal={setIsModal}
                            addToCart={() =>
                              addRewardsToCart(
                                item.title,
                                item.image,
                                item.points,
                                item.sku
                              )
                            }
                            isRewardLoading={
                              rewardsLoading.status &&
                              rewardsLoading.sku === item.sku
                            }
                          />
                        </div>
                      );
                    }
                    if (rewards.length === index + 1) {
                      return (
                        <div key={index} ref={lastCardElementRef} className="">
                          <RewardCard
                            {...item}
                            setModalData={(e: any) => setModalData(e)}
                            openModal={setIsModal}
                            addToCart={() =>
                              addRewardsToCart(
                                item.title,
                                item.image,
                                item.points,
                                item.sku
                              )
                            }
                            isChecked={addCartArr.includes(item.sku)}
                            isRewardLoading={
                              rewardsLoading.status &&
                              rewardsLoading.sku === item.sku
                            }
                            removeFromCart={() =>
                              removeRewardFromCart(
                                item.title,
                                item.image,
                                item.points,
                                item.sku
                              )
                            }
                          />
                        </div>
                      );
                    }
                    return (
                      <div key={index}>
                        <RewardCard
                          {...item}
                          setModalData={(e: any) => setModalData(e)}
                          openModal={setIsModal}
                          addToCart={() =>
                            addRewardsToCart(
                              item.title,
                              item.image,
                              item.points,
                              item.sku
                            )
                          }
                          isChecked={addCartArr.includes(item.sku)}
                          isRewardLoading={
                            rewardsLoading.status &&
                            rewardsLoading.sku === item.sku
                          }
                          removeFromCart={() =>
                            removeRewardFromCart(
                              item.title,
                              item.image,
                              item.points,
                              item.sku
                            )
                          }
                        />
                      </div>
                    );
                  }
                )}
              </section>
              {loading && (
                <div className=" flex justify-center items-center h-full w-full min-h-[10rem] ">
                  <div className="w-16 h-16">
                    <Spinner className="fill-[#F24E00]" />
                  </div>
                </div>
              )}
            </>
          ) : (
            <section className=" h-[calc(100vh-30vh)] xl:h-[77vh] flex items-center justify-center">
              <h2 className="text-lg font-medium">No Rewards Found</h2>
            </section>
          )}
        </div>
      )}
    </section>
  );
};

export default Rewards;

